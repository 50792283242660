<template>
    <div>
        <v-row>
            <v-col>
                <div class="d-flex">
                    <v-img src="/img/icons/info-circle.svg" height="1rem" width="1rem" style="flex-grow: 0; margin-top: 3px; margin-right: 5px" />
                    <p class="font-weight-bold my-0">{{ $t('contactInfo.subscribeNotificationsInfo')}}</p>
                </div>
                <v-checkbox
                v-model="addNotificationEmail"
                :label="$t('contactInfo.subscribeNotificationsBox')"
                color="#000"
                class="black--text"
                @click="toggleAddNotificationEmail"
                ></v-checkbox>
                <v-form v-if="addNotificationEmail" v-model="validNotificationEmail">
                    <v-row>
                        <v-col>
                            <label for="sähköpostiosoite">{{ $t('contactInfo.emailForNotifications')}}</label>
                            <v-text-field
                                id="sähköpostiosoite"
                                class="pt-0"
                                v-model="notificationEmail"
                                :rules="emailRules"
                                background-color="#EDEDEF"
                                solo
                                flat
                                hide-details="auto"
                                @input="setNotificationEmail">
                                <template #message="{ message }">{{ $t(message) }}</template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>   
        </v-row>
        <v-row>
            <v-col>
                <div class="d-flex" style="align-items: center;">
                    <v-img src="/img/icons/info-circle.svg" height="1rem" width="1rem" style="flex-grow: 0; margin-right: 5px" />
                    <p class="font-weight-bold my-0">{{ $t('contactInfo.contactInfoText')}}</p>
                </div>
                <v-checkbox
                    v-model="addContactInfo"
                    :label="$t('contactInfo.addContactInfo')"
                    color="#000"
                    class="black--text"
                    @click="toggleAddContactInfo"
                ></v-checkbox>
                <v-form v-if="addContactInfo" v-model="validContactInfo">
                    <v-row>
                        <v-col>
                            <label for="huolenaihe">{{ $t('contactInfo.name')}}</label>
                            <v-text-field
                                id="huolenaihe"
                                class="pt-0"
                                v-model="contactName"
                                :rules="inputRules"
                                background-color="#EDEDEF"
                                solo
                                flat
                                hide-details
                                @input="setContactInfo">                                
                                    <template #message="{ message }">{{ $t(message) }}</template>
                                </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <label for="huolenaihe">{{ $t('contactInfo.email')}}</label>
                            <v-text-field
                                id="huolenaihe"
                                class="pt-0 mb-0"
                                v-model="contactEmail"
                                :rules="emailRules"
                                background-color="#EDEDEF"
                                solo
                                flat
                                hide-details="auto"
                                @input="setContactInfo">                                
                                    <template #message="{ message }">{{ $t(message) }}</template>
                                </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <label for="huolenaihe">{{ $t('contactInfo.phone')}}</label>
                            <v-text-field
                                id="huolenaihe"
                                class="pt-0"
                                type="number"
                                v-model="contactPhone"
                                :rules="inputRules"
                                background-color="#EDEDEF"
                                solo
                                flat
                                hide-details
                                @input="setContactInfo">                                
                                    <template #message="{ message }">{{ $t(message) }}</template>
                                </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="d-flex">
                    <v-img src="/img/icons/info-circle.svg" height="1rem" width="1rem" style="flex-grow: 0; margin-top: 3px; margin-right: 5px" />
                    <p class="font-weight-bold my-0">{{ $t('contactInfo.attachmentsInfo')}}</p>
                </div>
                <div>
                    <v-file-input
                        multiple
                        :label="$t('contactInfo.addFile')"
                        truncate-length="10"
                        @change="selectFile"
                        >
                        </v-file-input>
                </div>
                <v-card class="mx-auto">
                    <v-list>
                        <v-subheader>{{ $t('contactInfo.addedFiles')}}</v-subheader>
                        <v-list-item-group color="primary">
                            <v-list-item v-for="(file, index) in this.$store.state.addedFiles" :key="index">
                                <a :href="file[0].url">{{ file[0].name }}</a>
                                    <button type="button" class="close" aria-label="Close" @click="deleteFile(index)">
                                        <span aria-hidden="true">×</span>
                                    </button>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    <v-btn
        :to="`/${this.$store.state.organization.urlName}/lomake`"
        depressed
        color="#424752"
        style="color: #fff; font-size: 1rem;position: absolute; left: 12px;"
        class="my-8">
            {{ $t('general.previous')}}
        </v-btn>
    <v-btn
        :disabled="!nextAllowed"
        :to="`/${this.$store.state.organization.urlName}/yhteenveto`"
        depressed
        color="#424752"
        style="color: #fff; font-size: 1rem;position: absolute; right: 12px;"
        class="my-8">
            {{ $t('general.next')}}
        </v-btn>
    </div>
</template>

<style scoped>
.black--text /deep/ label {
    color: black;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<script>

import {extensions} from '../data/extensions'

export default {
    data: function() {
        return {
            allDataValid: false,
            validContactInfo: false,
            validNotificationEmail: false,
            addContactInfo: this.$store.state.addContactInfo,
            addNotificationEmail: this.$store.state.addNotificationEmail,
            contactName: this.$store.state.contactName,
            contactEmail: this.$store.state.contactEmail,
            contactPhone: this.$store.state.contactPhone,
            notificationEmail: this.$store.state.notificationEmail,
            inputRules: [
                v => !!v || 'contactInfo.emptyFieldAlert'
            ],
            emailRules: [
                v => !!v || 'contactInfo.emptyFieldAlert',
                v => /.+@.+\..+/.test(v) || 'contactInfo.checkEmail',
            ],
            fileList: [],
            fromData: new FormData()
        }
    },
    methods: {
        toggleAddContactInfo: function() {
            this.$store.commit("updateContactInfo",
                {
                    name: '', 
                    email: '', 
                    phone: ''
                }
            );
            this.contactName = ''
            this.contactEmail = ''
            this.contactPhone = ''
            this.$store.commit("toggleAddContactInfo")
        },
        toggleAddNotificationEmail: function() {
            this.notificationEmail = ''
            this.$store.commit("updateNotificationEmail", this.notificationEmail)
            this.$store.commit("toggleAddNotificationEmail")
        },
        setContactInfo: function() {
            this.$store.commit("updateContactInfo",
                {
                    name: this.contactName, 
                    email: this.contactEmail, 
                    phone: this.contactPhone
                }
            );
        },
        setNotificationEmail: function() {
            this.$store.commit("updateNotificationEmail", this.notificationEmail)
        },
        selectFile: function(file) {
            let index = file[0].name.lastIndexOf(".")
            let extension = file[0].name.substring(index)
            if (extensions.includes(extension)) {
                this.fileList.push(file)
                this.$store.commit("updateAddedFiles", file)
            } else {
                this.$store.commit("setSnack", {
                    text: this.$i18n.locale == 'fi' ? "Tiedostomuoto ei sallittu!": "File format not allowed!",
                    message: "",
                    color: "error",
                });
            }
        },
        deleteFile: function(index) {
            this.$store.commit("deleteFile", index)
        }
    },
    computed: {
        nextAllowed: function() {
                if(!this.addContactInfo && !this.addNotificationEmail) { // User doesn't want to add data
                    return true
                } else if(this.addContactInfo && !this.addNotificationEmail) { // User wants to add contact information, but not an email for notifications
                    return this.validContactInfo // Return true or false based on whether contact info form is valid
                } else if(this.addNotificationEmail && !this.addContactInfo) { // User wants to add an email for notifications, but not contact information
                    return this.validNotificationEmail // Return true or false based on whether notification email form is valid

                } else if(this.addContactInfo && this.addNotificationEmail){ // User wants to add contact information and an email for notifications
                    if(this.validContactInfo && this.notificationEmail) { // Check if both forms have valid data
                        return true
                    } else {
                        return false
                    }
                }
                return true
            },
    },
    mounted() {

    }
}
</script>
