var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('v-img',{staticStyle:{"flex-grow":"0","margin-top":"3px","margin-right":"5px"},attrs:{"src":"/img/icons/info-circle.svg","height":"1rem","width":"1rem"}}),_c('p',{staticClass:"font-weight-bold my-0"},[_vm._v(_vm._s(_vm.$t('contactInfo.subscribeNotificationsInfo')))])],1),_c('v-checkbox',{staticClass:"black--text",attrs:{"label":_vm.$t('contactInfo.subscribeNotificationsBox'),"color":"#000"},on:{"click":_vm.toggleAddNotificationEmail},model:{value:(_vm.addNotificationEmail),callback:function ($$v) {_vm.addNotificationEmail=$$v},expression:"addNotificationEmail"}}),(_vm.addNotificationEmail)?_c('v-form',{model:{value:(_vm.validNotificationEmail),callback:function ($$v) {_vm.validNotificationEmail=$$v},expression:"validNotificationEmail"}},[_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"sähköpostiosoite"}},[_vm._v(_vm._s(_vm.$t('contactInfo.emailForNotifications')))]),_c('v-text-field',{staticClass:"pt-0",attrs:{"id":"sähköpostiosoite","rules":_vm.emailRules,"background-color":"#EDEDEF","solo":"","flat":"","hide-details":"auto"},on:{"input":_vm.setNotificationEmail},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(_vm._s(_vm.$t(message)))]}}],null,false,3811468480),model:{value:(_vm.notificationEmail),callback:function ($$v) {_vm.notificationEmail=$$v},expression:"notificationEmail"}})],1)],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('v-img',{staticStyle:{"flex-grow":"0","margin-right":"5px"},attrs:{"src":"/img/icons/info-circle.svg","height":"1rem","width":"1rem"}}),_c('p',{staticClass:"font-weight-bold my-0"},[_vm._v(_vm._s(_vm.$t('contactInfo.contactInfoText')))])],1),_c('v-checkbox',{staticClass:"black--text",attrs:{"label":_vm.$t('contactInfo.addContactInfo'),"color":"#000"},on:{"click":_vm.toggleAddContactInfo},model:{value:(_vm.addContactInfo),callback:function ($$v) {_vm.addContactInfo=$$v},expression:"addContactInfo"}}),(_vm.addContactInfo)?_c('v-form',{model:{value:(_vm.validContactInfo),callback:function ($$v) {_vm.validContactInfo=$$v},expression:"validContactInfo"}},[_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"huolenaihe"}},[_vm._v(_vm._s(_vm.$t('contactInfo.name')))]),_c('v-text-field',{staticClass:"pt-0",attrs:{"id":"huolenaihe","rules":_vm.inputRules,"background-color":"#EDEDEF","solo":"","flat":"","hide-details":""},on:{"input":_vm.setContactInfo},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(_vm._s(_vm.$t(message)))]}}],null,false,3811468480),model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=$$v},expression:"contactName"}})],1)],1),_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"huolenaihe"}},[_vm._v(_vm._s(_vm.$t('contactInfo.email')))]),_c('v-text-field',{staticClass:"pt-0 mb-0",attrs:{"id":"huolenaihe","rules":_vm.emailRules,"background-color":"#EDEDEF","solo":"","flat":"","hide-details":"auto"},on:{"input":_vm.setContactInfo},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(_vm._s(_vm.$t(message)))]}}],null,false,3811468480),model:{value:(_vm.contactEmail),callback:function ($$v) {_vm.contactEmail=$$v},expression:"contactEmail"}})],1)],1),_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"huolenaihe"}},[_vm._v(_vm._s(_vm.$t('contactInfo.phone')))]),_c('v-text-field',{staticClass:"pt-0",attrs:{"id":"huolenaihe","type":"number","rules":_vm.inputRules,"background-color":"#EDEDEF","solo":"","flat":"","hide-details":""},on:{"input":_vm.setContactInfo},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(_vm._s(_vm.$t(message)))]}}],null,false,3811468480),model:{value:(_vm.contactPhone),callback:function ($$v) {_vm.contactPhone=$$v},expression:"contactPhone"}})],1)],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('v-img',{staticStyle:{"flex-grow":"0","margin-top":"3px","margin-right":"5px"},attrs:{"src":"/img/icons/info-circle.svg","height":"1rem","width":"1rem"}}),_c('p',{staticClass:"font-weight-bold my-0"},[_vm._v(_vm._s(_vm.$t('contactInfo.attachmentsInfo')))])],1),_c('div',[_c('v-file-input',{attrs:{"multiple":"","label":_vm.$t('contactInfo.addFile'),"truncate-length":"10"},on:{"change":_vm.selectFile}})],1),_c('v-card',{staticClass:"mx-auto"},[_c('v-list',[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('contactInfo.addedFiles')))]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((this.$store.state.addedFiles),function(file,index){return _c('v-list-item',{key:index},[_c('a',{attrs:{"href":file[0].url}},[_vm._v(_vm._s(file[0].name))]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.deleteFile(index)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])}),1)],1)],1)],1)],1),_c('v-btn',{staticClass:"my-8",staticStyle:{"color":"#fff","font-size":"1rem","position":"absolute","left":"12px"},attrs:{"to":("/" + (this.$store.state.organization.urlName) + "/lomake"),"depressed":"","color":"#424752"}},[_vm._v(" "+_vm._s(_vm.$t('general.previous'))+" ")]),_c('v-btn',{staticClass:"my-8",staticStyle:{"color":"#fff","font-size":"1rem","position":"absolute","right":"12px"},attrs:{"disabled":!_vm.nextAllowed,"to":("/" + (this.$store.state.organization.urlName) + "/yhteenveto"),"depressed":"","color":"#424752"}},[_vm._v(" "+_vm._s(_vm.$t('general.next'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }